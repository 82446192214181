import React, { FC } from "react";
import {Link, useNavigate} from "react-router-dom";
import { Navigate } from "react-router-dom";
// import { useAuth0 } from "@auth0/auth0-react";
import {Button} from "./Button";
// import { generateLoginURL, generateLogoutURL } from "@cloudflare/pages-plugin-cloudflare-access/api";

type Props = {
  title?: string;
  home?: boolean;
  setup?: boolean;
  admin?: boolean;
}

export const Header: FC<Props> = ({ title, setup = true, home = true, admin = true }) => {
  // const { user, isAuthenticated, isLoading, loginWithRedirect, logout} = useAuth0();

  // if (isLoading) {
  //   return <div>Loading ...</div>;
  // }

  const showLogin = true;//user === undefined;
  const showLogout = false;//user !== undefined;

  // const loginURL = generateLoginURL({
  //   redirectURL: window.location.origin,
  //   domain: "https://prana.cloudflareaccess.com",
  //   aud: "cae457f7453ce71970b1b3fe6c85482c9b1bfe96e88c68e8819d7bbf5649d780",
  // });

  // const logoutUrl = generateLogoutURL({
  //   domain: "https://prana.cloudflareaccess.com",
  // })

  return (
    <div className="pt-4 pb-6 mb-8 bg-blue-900 sticky top-0 z-10">
      <header className="flex items-center text-blue-50 max-w-6xl mx-auto">
        <h1 className="flex-1 text-lg md:text-xl lg:text-2xl">
          {title ?? ""}
        </h1>
        {home ? (
            <nav className="ml-4">
              <ul>
                <li>
                  <Link to="/" className="underline">
                    Home
                  </Link>
                </li>
              </ul>
            </nav>
        ) : undefined}
        {admin ? (
          <nav className="ml-4">
            <ul>
              <li>
                <Link to="/admin" className="underline">
                  Admin
                </Link>
              </li>
            </ul>
          </nav>
        ) : undefined}
        {setup ? (
            <nav className="ml-4">
              <ul>
                <li>
                  <Link to="/setup" className="underline">
                    Setup
                  </Link>
                </li>
              </ul>
            </nav>
        ) : undefined}
        {showLogin ? (
            <nav className="ml-4">
              <ul>
                <li>
                  <a
                      href="https://prana.cloudflareaccess.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                  >
                    Login
                  </a>
                </li>
              </ul>
            </nav>
        ) : undefined}
        {showLogout ? (
            <nav className="ml-4">
              <ul>
                <li>
                  <a
                      href="https://prana.cloudflareaccess.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </nav>
        ) : undefined}
      </header>
    </div>
  );
};
