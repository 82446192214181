import {useEffect, useRef, useState} from "react";
import {fetchApi} from "../utils/apiFetcher";

export const useApi = <T extends Json>(params: ApiParams) => {
    const [loading, setLoading] = useState(true)
    const [result, setResult] = useState<ApiResult<T>>();
    const isMounted = useRef(true);

    useEffect(() => {
        (async () => {
            const result = await fetchApi<T>(params);
            if (isMounted.current) {
                setResult(result);
                setLoading(false);
            }
        })()

        return () => {
            isMounted.current = false;
        }
    }, [params])

    return {result, loading};
}