// summary: fetchApi function to make API requests
export const fetchApi = async <T extends Json>(params: ApiParams): Promise<ApiResult<T>> => {
    const {endpoint, method, headers, body} = params;
    console.log('fetchApi', endpoint, method, headers, body)
    try {
        const response = await fetch(endpoint, {
            method: method ?? 'GET',
            headers: {...params.headers, "CF-Access-Client-Id": process.env.REACT_APP_PRANA_SERVICE_TOKEN ?? ""},
            body,
        });
        const data = await response.json() as T;
        return {
            status: response.status,
            data,
        };
    } catch (error: any) {
        return {
            status: 500,
            error: error.message,
        };
    }
}