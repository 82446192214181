import React, { FC } from "react";
import ChannelBar from './components/ChannelBar';
import ContentContainer from './components/ContentContainer';
import SideBar from './components/SideBar';

export const OriginHome: FC<{ origin: string }> = ({ origin }) => {
    return (
        <div className="flex">
            <SideBar />
            <ChannelBar />
            <ContentContainer />
        </div>
    );
};
