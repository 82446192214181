import React, { FC } from "react";
import { Header } from "../components/Header";
import {OriginHome} from "./[origin]/Home";
import {ImageGrid} from "../components/ImageGrid";
import {useOrigin} from "../hooks/useOrigin";
// import {origin} from "../signals/origin";
// import {useSignals} from "@preact/signals-react/runtime";

export const Home: FC = () => {
    const {origin} = useOrigin()
    if (origin) {
        return <OriginHome origin={origin}/>
    } else {
        return (
            <div>
                <Header title="prana.codes" home={false} />
                <main className="max-w-6xl mx-auto p-4 pb-12">
                    <ImageGrid />
                </main>
            </div>
        );
    }
};
