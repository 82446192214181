import React, { PropsWithChildren } from "react";
import {useAccess} from "../hooks/useAccess";
import Loader from "../components/Loader";
import Forbidden from "../components/Forbidden";

type Props = {
    children: React.ReactNode;
}

export const AdminGuard: React.FC<PropsWithChildren<Props>> = ({ children }) => {
    const { accessGranted, loading} = useAccess()
    if (loading) {
        return <Loader/>
    }
    if (!accessGranted) {
        return <Forbidden />
    }
  return <>{children}</>;
};