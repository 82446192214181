import React, {FC} from "react";
import {Header} from "../components/Header";
import OriginAdmin from "./[origin]/Admin";
import {AdminGuard} from "../providers/AdminGuard";
import {useOrigin} from "../hooks/useOrigin";

export const Admin: FC = () => {
const {origin} = useOrigin()

    if (origin) {
        return <OriginAdmin origin={origin} />
    }

    const title = origin ? `${origin} admin section` : 'admin section';
    return (
        <AdminGuard>
            <Header title={title} admin={false}/>
            <main className="max-w-6xl mx-auto p-4 pb-12">
                <form method="POST" action="/admin/api/upload">
                    <input type="file" name="file"/>
                    <button
                        type="submit"
                        className="rounded-lg shadow-md py-3 px-5 cursor-pointer inline-block bg-blue-100 text-blue-800"
                    >
                        Submit →
                    </button>
                </form>
            </main>
        </AdminGuard>
    );
};
