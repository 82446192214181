import React, {useEffect} from "react";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import { Home } from "./pages/Home";
import { Setup } from "./pages/Setup";
import { Admin } from "./pages/Admin";
import Error from "./pages/Error";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <Error />,
    },
    {
        path: "setup/",
        element: <Setup />,
    },
    {
        path: "admin/",
        element: <Admin />,
    },
])

export const App = () => {
    return (
        <RouterProvider router={router} />
    );
};