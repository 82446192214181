import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
//import { Auth0Provider } from '@auth0/auth0-react';
import { config } from 'dotenv';
config();

ReactDOM.render(
  <React.StrictMode>
      {/*<Auth0Provider*/}
      {/*    domain="prana-codes.eu.auth0.com"*/}
      {/*    clientId="HiX7Dk4gtKXBv4nhot3SGSv2qFt2CGLj"*/}
      {/*    authorizationParams={{*/}
      {/*        redirect_uri: window.location.origin*/}
      {/*    }}*/}
      {/*>*/}
          <App />
      {/*</Auth0Provider>,*/}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
