
import React from "react";
import {Header} from "../../components/Header";
import {AdminGuard} from "../../providers/AdminGuard";

const OriginSetup: React.FC<{origin: string}> = () => {
    return (
        <AdminGuard>
            <Header title={`${origin} setup`} setup={false} />
            <main className="max-w-6xl mx-auto p-4 pb-12">
                <h1 className="text-2xl">
                    {origin}
                </h1>
                <p className="text-red-500 text-xl">{`setup page for ${origin}`}</p>
            </main>
        </AdminGuard>
    )
}

export default OriginSetup;